body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .mainContainer{
    background-color: #EACAB5;
  }
  
  .container {
    background-color: #EACAB5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  a:hover {
    cursor: pointer;
  }
  
  .btn-circle.btn-sm { 
    width: 30px; 
    height: 30px; 
    padding: 6px 0px; 
    border-radius: 50%; 
    font-size: 8px; 
    text-align: center; 
  } 
  .btn-circle.btn-md { 
    width: 85px; 
    height: 85px; 
    padding: 7px 10px; 
    border-radius: 50%; 
    font-size: 24px; 
    text-align: center; 
  }
  .btn-circle.btn-lg { 
    width: 160px; 
    height: 160px; 
    padding: 10px 16px; 
    border-radius: 50%; 
    font-size: 18px; 
    text-align: center; 
  }
  .btn-circle.btn-xl2 { 
    width: 250px; 
    height: 250px; 
    padding: 10px 16px; 
    border-radius: 50%; 
    font-size: 30px; 
    text-align: center; 
  }   
  .btn-circle.btn-xl { 
    width: 250px; 
    height: 250px; 
    padding: 10px 16px; 
    border-radius: 50%; 
    font-size: 70px; 
    text-align: center; 
  } 
  
  .background-red {
    background-color: #d9534f;
  }
  
  .background-green {
    background-color: #5cb85c;
  }
  
  .background-transparent {
    background-color: none;
  }
  